var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "a-form",
        {
          staticStyle: { "max-width": "500px", margin: "40px auto 0" },
          attrs: { form: _vm.form },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.nextStep.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "a-form-item",
            [
              _c(
                "a-input",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: ["username", _vm.validatorRules.username],
                      expression: "['username',validatorRules.username]",
                    },
                  ],
                  attrs: {
                    size: "large",
                    type: "text",
                    autocomplete: "false",
                    placeholder: "请输入用户账号或手机号",
                  },
                },
                [
                  _c("a-icon", {
                    style: { color: "rgba(0,0,0,.25)" },
                    attrs: { slot: "prefix", type: "lock" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 0 } },
            [
              _c(
                "a-col",
                { attrs: { span: 14 } },
                [
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-input",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "inputCode",
                                _vm.validatorRules.inputCode,
                              ],
                              expression:
                                "['inputCode',validatorRules.inputCode]",
                            },
                          ],
                          attrs: {
                            size: "large",
                            type: "text",
                            placeholder: "请输入验证码",
                          },
                          on: { change: _vm.inputCodeChange },
                        },
                        [
                          _vm.inputCodeContent == _vm.verifiedCode
                            ? _c("a-icon", {
                                style: { color: "rgba(0,0,0,.25)" },
                                attrs: { slot: "prefix", type: "smile" },
                                slot: "prefix",
                              })
                            : _c("a-icon", {
                                style: { color: "rgba(0,0,0,.25)" },
                                attrs: { slot: "prefix", type: "frown" },
                                slot: "prefix",
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 10 } },
                [
                  _vm.requestCodeSuccess
                    ? _c("img", {
                        staticStyle: { "margin-top": "2px" },
                        attrs: { src: _vm.randCodeImage },
                        on: { click: _vm.handleChangeCheckCode },
                      })
                    : _c("img", {
                        staticStyle: { "margin-top": "2px" },
                        attrs: {
                          src: require("../../../assets/checkcode.png"),
                        },
                        on: { click: _vm.handleChangeCheckCode },
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { wrapperCol: { span: 19, offset: 5 } } },
            [
              _c(
                "router-link",
                {
                  staticStyle: { float: "left", "line-height": "40px" },
                  attrs: { to: { name: "login" } },
                },
                [_vm._v("使用已有账户登录")]
              ),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.nextStep } },
                [_vm._v("下一步")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }